/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import FusionCharts from 'fusioncharts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import {
  State,
  Wrapper,
  Label,
  LabelText,
  Data,
  DataText,
  Charts,
  ChartsColumn,
  ChartsValue,
  ChartsLabel,
} from './styled';

ReactFC.fcRoot(FusionCharts, Widgets, FusionTheme);

const Table = ({ dataProvier, dataInput }) => {
  const dataSourceCurrent = {
    chart: {
      // caption: 'Current',
      // subcaption: 'AMP',
      captionFontSize: '16',
      captionFontColor: '#000000',
      subcaptionFontSize: '14',
      lowerLimit: '0',
      upperLimit: '100',
      theme: 'fusion',
    },
    colorRange: {
      color: [
        {
          minValue: '0',
          maxValue: '50',
          code: '#fd5f53',
        },
        {
          minValue: '50',
          maxValue: '100',
          code: '#22cc36',
        },
      ],
    },
    dials: {
      dial: [
        {
          value: dataInput.current,
        },
      ],
    },
  };

  const dataSourceFlow = {
    chart: {
      // caption: 'Water Flow',
      // subcaption: 'LPM',
      captionFontSize: '16',
      captionFontColor: '#000000',
      subcaptionFontSize: '14',
      lowerLimit: '0',
      upperLimit: '100',
      theme: 'fusion',
    },
    colorRange: {
      color: [
        {
          minValue: '0',
          maxValue: '50',
          code: '#fd5f53',
        },
        {
          minValue: '50',
          maxValue: '100',
          code: '#22cc36',
        },
      ],
    },
    dials: {
      dial: [
        {
          value: dataInput.flow,
        },
      ],
    },
  };

  const dataSourcePressure = {
    chart: {
      // caption: 'Pressure',
      // subcaption: 'AMP',
      captionFontSize: '16',
      captionFontColor: '#000000',
      subcaptionFontSize: '14',
      lowerLimit: '0',
      upperLimit: '100',
      theme: 'fusion',
    },
    colorRange: {
      color: [
        {
          minValue: '0',
          maxValue: '50',
          code: '#fd5f53',
        },
        {
          minValue: '50',
          maxValue: '100',
          code: '#22cc36',
        },
      ],
    },
    dials: {
      dial: [
        {
          value: dataInput.pressure,
        },
      ],
    },
  };

  const dataSourceTemp1 = {
    chart: {
      // caption: 'Waterproof Temp 1',
      // subcaption: '°C',
      captionFontSize: '16',
      captionFontColor: '#000000',
      subcaptionFontSize: '14',
      lowerLimit: '0',
      upperLimit: '100',
      theme: 'fusion',
    },
    colorRange: {
      color: [
        {
          minValue: '0',
          maxValue: '50',
          code: '#fd5f53',
        },
        {
          minValue: '50',
          maxValue: '100',
          code: '#22cc36',
        },
      ],
    },
    dials: {
      dial: [
        {
          value: dataInput.temp1,
        },
      ],
    },
  };

  const dataSourceTemp2 = {
    chart: {
      // caption: 'Waterproof Temp 2',
      // subcaption: '°C',
      captionFontSize: '16',
      captionFontColor: '#000000',
      subcaptionFontSize: '14',
      lowerLimit: '0',
      upperLimit: '100',
      theme: 'fusion',
    },
    colorRange: {
      color: [
        {
          minValue: '0',
          maxValue: '50',
          code: '#fd5f53',
        },
        {
          minValue: '50',
          maxValue: '100',
          code: '#22cc36',
        },
      ],
    },
    dials: {
      dial: [
        {
          value: dataInput.temp2,
        },
      ],
    },
  };

  const chartConfigsCurrent = {
    type: 'angulargauge',
    width: '100%',
    height: '200',
    dataFormat: 'json',
    dataSource: dataSourceCurrent,
  };

  const chartConfigsFlow = {
    type: 'angulargauge',
    width: '100%',
    height: '200',
    dataFormat: 'json',
    dataSource: dataSourceFlow,
  };

  const chartConfigsPressure = {
    type: 'angulargauge',
    width: '100%',
    height: '200',
    dataFormat: 'json',
    dataSource: dataSourcePressure,
  };

  const chartConfigsTemp1 = {
    type: 'angulargauge',
    width: '100%',
    height: '200',
    dataFormat: 'json',
    dataSource: dataSourceTemp1,
  };

  const chartConfigsTemp2 = {
    type: 'angulargauge',
    width: '100%',
    height: '200',
    dataFormat: 'json',
    dataSource: dataSourceTemp2,
  };

  return (
    <State>
      <Wrapper>
        <Label>
          <LabelText>Provier</LabelText>
          <LabelText>Current</LabelText>
          <LabelText>Water Flow</LabelText>
          <LabelText>Pressure</LabelText>
          <LabelText>Waterproof Temp 1</LabelText>
          <LabelText>Waterproof Temp 2</LabelText>
        </Label>

        <Data>
          <DataText>{dataProvier}</DataText>
          <DataText>{dataInput.current} AMP</DataText>
          <DataText>{dataInput.flow} LPM</DataText>
          <DataText>{dataInput.pressure} PSI</DataText>
          <DataText>{dataInput.temp1} °C</DataText>
          <DataText>{dataInput.temp2} °C</DataText>
        </Data>
      </Wrapper>

      <Charts>
        <ChartsColumn>
          <ReactFC {...chartConfigsCurrent} />
          <ChartsValue>{dataInput.current} AMP</ChartsValue>
          <ChartsLabel>Current</ChartsLabel>
        </ChartsColumn>

        <ChartsColumn>
          <ReactFC {...chartConfigsFlow} />
          <ChartsValue>{dataInput.flow} LPM</ChartsValue>
          <ChartsLabel>Water Flow</ChartsLabel>
        </ChartsColumn>

        <ChartsColumn>
          <ReactFC {...chartConfigsPressure} />
          <ChartsValue>{dataInput.pressure} PSI</ChartsValue>
          <ChartsLabel>Pressure</ChartsLabel>
        </ChartsColumn>

        <ChartsColumn>
          <ReactFC {...chartConfigsTemp1} />
          <ChartsValue>{dataInput.temp1} °C</ChartsValue>
          <ChartsLabel>Waterproof Temp 1</ChartsLabel>
        </ChartsColumn>

        <ChartsColumn>
          <ReactFC {...chartConfigsTemp2} />
          <ChartsValue>{dataInput.temp2} °C</ChartsValue>
          <ChartsLabel>Waterproof Temp 2</ChartsLabel>
        </ChartsColumn>
      </Charts>
    </State>
  );
};

Table.propTypes = {
  dataProvier: PropTypes.string.isRequired,
  dataCurrent: PropTypes.number.isRequired,
  dataFlow: PropTypes.number.isRequired,
  dataPre: PropTypes.number.isRequired,
  dataTemp1: PropTypes.number.isRequired,
  dataTemp2: PropTypes.number.isRequired,
};

export default Table;
