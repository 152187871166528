/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import firebase from 'firebase';
import { useObjectVal } from 'react-firebase-hooks/database';
import Layout from '../layout';
import { Loading } from '../screens';
import { List, Table, Overview } from '../components';

import { Header, Title, Filter, FilterLabel, FilterButton } from './styled';

require('firebase/auth');
require('firebase/database');

const App = () => {
  const [alldevice, setAlldevice] = useState(true);
  const [stl1ldevice, setStl1ldevice] = useState(true);
  const [stl1rdevice, setStl1rdevice] = useState(true);
  const [stl4ldevice, setStl4ldevice] = useState(true);
  const [stl4rdevice, setStl4rdevice] = useState(true);

  const handleAlldevice = () => {
    setAlldevice(true);
    setStl1ldevice(true);
    setStl1rdevice(true);
    setStl4ldevice(true);
    setStl4rdevice(true);
  };

  const handleStl1ldevice = () => {
    setAlldevice(false);
    setStl1ldevice(true);
    setStl1rdevice(false);
    setStl4ldevice(false);
    setStl4rdevice(false);
  };

  const handleStl1rdevice = () => {
    setAlldevice(false);
    setStl1ldevice(false);
    setStl1rdevice(true);
    setStl4ldevice(false);
    setStl4rdevice(false);
  };

  const handleStl4ldevice = () => {
    setAlldevice(false);
    setStl1ldevice(false);
    setStl1rdevice(false);
    setStl4ldevice(true);
    setStl4rdevice(false);
  };

  const handleStl4rdevice = () => {
    setAlldevice(false);
    setStl1ldevice(false);
    setStl1rdevice(false);
    setStl4ldevice(false);
    setStl4rdevice(true);
  };

  const [stl1LDataInput, stl1LDataInputLoading] = useObjectVal(
    firebase.database().ref('/provider/STL1L/location/1/device/input'),
  );

  const [stl1rDataInput, stl1rDataInputLoading] = useObjectVal(
    firebase.database().ref('/provider/STL1R/location/1/device/input'),
  );

  const [stl4lDataInput, stl4lDataInputLoading] = useObjectVal(
    firebase.database().ref('/provider/STL4L/location/4/device/input'),
  );

  const [stl4rDataInput, stl4rDataInputLoading] = useObjectVal(
    firebase.database().ref('/provider/STL4R/location/1/device/input'),
  );

  if (
    !stl1LDataInputLoading &&
    stl1LDataInput &&
    !stl1rDataInputLoading &&
    stl1rDataInput &&
    !stl4lDataInputLoading &&
    stl4lDataInput &&
    !stl4rDataInputLoading &&
    stl4rDataInput
  ) {
    return (
      <Layout>
        <Overview total={4} runing={0} Stop={0} />
        <Header>
          <Title>Device Data</Title>

          <Filter>
            <FilterLabel>Filter:</FilterLabel>

            <FilterButton
              onClick={handleAlldevice}
              active={alldevice}
              type="button">
              <span>Show </span>All <span> Devices</span>
            </FilterButton>

            <FilterButton
              onClick={handleStl1ldevice}
              active={stl1ldevice}
              type="button">
              STL1L
            </FilterButton>

            <FilterButton
              onClick={handleStl1rdevice}
              active={stl1rdevice}
              type="button">
              STL1R
            </FilterButton>

            <FilterButton
              onClick={handleStl4ldevice}
              active={stl4ldevice}
              type="button">
              STL4L
            </FilterButton>

            <FilterButton
              onClick={handleStl4rdevice}
              active={stl4rdevice}
              type="button">
              STL4R
            </FilterButton>
          </Filter>
        </Header>

        {stl1ldevice && (
          <>
            <List dataProvier="STL1L" dataInput={stl1LDataInput} />
            <Table dataProvier="STL1L" dataInput={stl1LDataInput} />
          </>
        )}

        {stl1rdevice && (
          <>
            <List dataProvier="STL1R" dataInput={stl1rDataInput} />
            <Table dataProvier="STL1R" dataInput={stl1rDataInput} />
          </>
        )}

        {stl4ldevice && (
          <>
            <List dataProvier="STL4L" dataInput={stl4lDataInput} />
            <Table dataProvier="STL4L" dataInput={stl4lDataInput} />
          </>
        )}

        {stl4rdevice && (
          <>
            <List dataProvier="STL4R" dataInput={stl4rDataInput} />
            <Table dataProvier="STL4R" dataInput={stl4rDataInput} />
          </>
        )}
      </Layout>
    );
  }

  return <Loading />;
};

export default App;
