import PropTypes from 'prop-types';
import Header from './header';
import Titlebar from './titlebar';
import Footer from './footer';
import State from './styled';

const Layout = ({ children }) => {
  // Logic Here
  return (
    <State>
      <Header />
      <Titlebar />
      <main>{children}</main>
      <Footer />
    </State>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
