/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { calcrem } from '../../utils';

const State = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(61, 90, 254);
  color: #ffffff;
  font-weight: 700;
  z-index: 999;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${calcrem(84)};
  height: ${calcrem(84)};
  margin: 0 0 ${calcrem(16)};
  background-color: #ffffff;
  border-radius: ${calcrem(8)};
`;

const IconLogo = styled.svg`
  width: ${calcrem(64)};
  height: ${calcrem(64)};
  fill: rgb(61, 90, 254);
`;

export { State, Icon, IconLogo };
