import styled from 'styled-components';
import { calcrem } from '../utils';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;

  @media (max-width: 767.98px) {
    padding: 0 ${calcrem(16)};
    margin: 0 0 ${calcrem(32)};
  }

  @media (min-width: 768px) {
    padding: 0 ${calcrem(32)};
    margin: 0 0 ${calcrem(16)};
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 700;

  @media (max-width: 767.98px) {
    font-size: ${calcrem(18)};
  }

  @media (min-width: 768px) {
    font-size: ${calcrem(24)};
  }
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767.98px) {
  }

  @media (min-width: 768px) {
  }
`;

const FilterLabel = styled.span`
  display: block;
  font-weight: 700;

  @media (max-width: 767.98px) {
    display: none;
    margin: 0 ${calcrem(8)} 0 0;
    font-size: ${calcrem(15)};
  }

  @media (min-width: 768px) {
    display: block;
    margin: 0 ${calcrem(16)} 0 0;
    font-size: ${calcrem(16)};
  }
`;

const FilterButton = styled.button`
  transition: background-color 0.25s ease 0s;
  background-color: rgb(61, 90, 254);
  border: none;
  border-radius: ${calcrem(4)};
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  overflow: hidden;

  @media (max-width: 767.98px) {
    padding: ${calcrem(3)} ${calcrem(8)} ${calcrem(3)};
    margin: 0 ${calcrem(4)} 0 0;
    font-size: ${calcrem(14)};

    span {
      display: none;
    }
  }

  @media (min-width: 768px) {
    padding: ${calcrem(4)} ${calcrem(8)};
    margin: 0 ${calcrem(8)} 0 0;
    font-size: ${calcrem(14)};
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: rgb(31, 45, 127);
    color: #ffffff;
  }

  &:focus {
    outline: 0;
  }
`;

export { Header, Title, Filter, FilterLabel, FilterButton };
