import styled from 'styled-components';
import { calcrem } from '../../utils';

const State = styled.div`
  width: calcrem(100% - ${calcrem(32)});
  position: relative;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.125);
  border-radius: ${calcrem(4)};
  overflow: hidden;

  @media (max-width: 767.98px) {
    margin: 0 ${calcrem(16)} ${calcrem(32)};
  }

  @media (min-width: 768px) {
    display: none;
  }

  &:last-child {
    margin-bottom: ${calcrem(48)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const Label = styled.div`
  width: 50%;
  position: relative;
  background: linear-gradient(
    90deg,
    rgb(61, 90, 254) 0%,
    rgb(31, 45, 127) 100%
  );
  color: #ffffff;
  font-size: ${calcrem(17)};
  font-weight: 700;
`;

const LabelText = styled.div`
  padding: ${calcrem(16)};
  border-bottom: rgba(255, 255, 255, 0.25) 1px solid;

  &:last-child {
    border-bottom: none;
  }
`;

const Data = styled.div`
  width: 50%;
  position: relative;
  background-color: rgb(233, 233, 240);
  text-align: right;
  font-size: ${calcrem(17)};
`;

const DataText = styled.div`
  padding: ${calcrem(16)};
  border-bottom: rgba(255, 255, 255, 0.75) 1px solid;

  &:last-child {
    border-bottom: none;
  }
`;

const Charts = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
`;

const ChartsColumn = styled.div`
  height: 100%;
  position: relative;
  margin: ${calcrem(16)} 0 0;
  z-index: 9;

  svg > g[class^='raphael-group-'] > text {
    display: none;
  }

  @media (max-width: 767.98px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 50%;
  }
`;
const ChartsValue = styled.span`
  display: block;
  width: 100%;
  margin: 0 0 ${calcrem(8)};
  font-size: ${calcrem(15)};
  text-align: center;
`;

const ChartsLabel = styled.span`
  display: block;
  width: 100%;
  margin: 0 0 ${calcrem(32)};
  font-size: ${calcrem(15)};
  font-weight: 500;
  text-align: center;
`;
export {
  State,
  Wrapper,
  Label,
  LabelText,
  Data,
  DataText,
  Charts,
  ChartsColumn,
  ChartsValue,
  ChartsLabel,
};
