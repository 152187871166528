import React, { createContext } from 'react';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';

const config = {
  apiKey: 'AIzaSyBGgpv5mvhD2kA7rA8cFgLJS-UAG_8tZMc',
  authDomain: 'water-int-pad-68e07.firebaseapp.com',
  databaseURL: 'https://water-int-pad-68e07.firebaseio.com',
  projectId: 'water-int-pad-68e07',
  storageBucket: 'water-int-pad-68e07.appspot.com',
  messagingSenderId: '278832427080',
  appId: '1:278832427080:web:4d724f649116446c9e687d',
  measurementId: 'G-EE5ZP3KC7Q',
};

const FirebaseProvider = ({ children }) => {
  const Firebase = createContext(null);

  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }

  return <Firebase.Provider>{children}</Firebase.Provider>;
};

FirebaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FirebaseProvider;
