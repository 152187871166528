import styled from 'styled-components';
import { calcrem } from '../../utils';

const State = styled.div`
  width: 100%;
  padding: ${calcrem(16)} 0;
  position: sticky;
  top: ${calcrem(48)};
  background-color: rgb(255, 255, 255);
  border-bottom: 0.0625rem solid rgb(225, 228, 232);
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.125);
  z-index: 999;

  @media (max-width: 767.98px) {
    margin: 0 0 ${calcrem(32)};
  }

  @media (min-width: 768px) {
    margin: 0 0 ${calcrem(64)};
  }
`;

const Title = styled.div`
  flex: 1 1 0%;
  margin: 0;
  color: rgb(0, 0, 0);

  font-weight: 700;

  @media (max-width: 767.98px) {
    font-size: ${calcrem(18)};
  }

  @media (min-width: 768px) {
    font-size: ${calcrem(24)};
  }
`;

const Support = styled.div`
  display: flex;
  align-items: center;
`;

const SupportLabel = styled.div`
  display: block;
  margin: 0 ${calcrem(16)} 0 0;
  font-weight: 700;

  @media (max-width: 767.98px) {
    font-size: ${calcrem(16)};
  }

  @media (min-width: 768px) {
    font-size: ${calcrem(16)};
  }
`;

const SupportLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${calcrem(8)} 0 0;
  transition: background-color 0.25s ease 0s;
  background-color: rgb(61, 90, 254);
  border-radius: 0.25rem;
  color: #ffffff;
  font-size: ${calcrem(14)};
  font-weight: 700;
  text-decoration: none;
  overflow: hidden;

  @media (max-width: 767.98px) {
    padding: ${calcrem(6)} ${calcrem(8)};
  }

  @media (min-width: 768px) {
    padding: ${calcrem(6)} ${calcrem(16)} ${calcrem(6)} ${calcrem(8)};
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: rgb(31, 45, 127);
    color: #ffffff;
  }
`;

const SupportIcon = styled.svg`
  width: 1.3125rem;
  height: 1.3125rem;
  fill: rgb(255, 255, 255);

  @media (max-width: 767.98px) {
  }

  @media (min-width: 768px) {
    margin: 0 ${calcrem(8)} 0 0;
  }
`;

const SupportText = styled.span`
  @media (max-width: 767.98px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;

export {
  State,
  Title,
  Support,
  SupportLink,
  SupportLabel,
  SupportIcon,
  SupportText,
};
