/* eslint-disable no-use-before-define */
import styled from 'styled-components';
import { calcrem } from '../../utils';

const State = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${calcrem(48)};
  position: sticky;
  top: 0;
  background-color: #000000;
  z-index: 999;
`;

const Brand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${calcrem(32)};
  height: ${calcrem(32)};
  margin: 0 ${calcrem(16)} 0 0;
  background-color: rgb(61, 90, 254);
  border-radius: 50%;
  overflow: hidden;
`;

const BrandLogo = styled.svg`
  width: ${calcrem(24)};
  height: ${calcrem(24)};
  fill: #ffffff;
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  color: rgba(255, 255, 255, 1);
  font-size: ${calcrem(18)};
`;

const Action = styled.div`
  display: flex;
  align-items: center;
`;

const ActionProfile = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 767.98px) {
    margin: 0 ${calcrem(8)} 0 0;
  }

  @media (min-width: 768px) {
    margin: 0 ${calcrem(32)} 0 0;
  }
`;

const ActionProfileLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${calcrem(28)};
  height: ${calcrem(28)};
  margin: 0 ${calcrem(8)} 0 0;
  border: ${calcrem(2)} solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
`;

const ActionProfileImage = styled.img`
  width: 100%;
  max-width: 100%;
`;

const ActionProfileName = styled.span`
  color: rgba(255, 255, 255, 1);
  font-size: ${calcrem(14)};

  @media (max-width: 767.98px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;

const ActionLogout = styled.button`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  transition: opacity 0.25s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.5;
  }
`;

const ActionLogoutText = styled.span`
  color: rgba(255, 255, 255, 1);
  font-size: ${calcrem(14)};

  @media (max-width: 767.98px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;

const ActionLogoutIcon = styled.svg`
  fill: #ffffff;

  @media (max-width: 767.98px) {
    width: ${calcrem(24)};
    height: ${calcrem(24)};
    margin: 0 ${calcrem(-8)} 0 0;
  }

  @media (min-width: 768px) {
    width: ${calcrem(20)};
    height: ${calcrem(20)};
    margin: 0 ${calcrem(8)} 0 0;
  }
`;

export {
  State,
  Brand,
  BrandLogo,
  Title,
  Action,
  ActionProfile,
  ActionLogout,
  ActionProfileLogo,
  ActionProfileImage,
  ActionProfileName,
  ActionLogoutText,
  ActionLogoutIcon,
};
