import PropTypes from 'prop-types';
import {
  State,
  Title,
  Data,
  DataCard,
  DataCardStatus,
  DataCardStatusTitle,
  DataCardStatusIcon,
  DataCardStat,
  DataCardStatNumber,
  DataCardStatUnit,
} from './styled';

const Overview = ({ total, runing, Stop }) => (
  <State>
    <Title>Overview</Title>

    <Data>
      <DataCard total>
        <DataCardStatus border>
          <DataCardStatusIcon viewBox="0 0 24 24" total>
            <path d="M3,4.8v3.6h18V4.8H3z M6.6,7.5H4.8V5.7h1.8V7.5z M3,19.2h18v-3.6H3V19.2z M4.8,16.5h1.8v1.8H4.8V16.5z M3,13.8 h18v-3.6H3V13.8z M4.8,11.1h1.8v1.8H4.8V11.1z" />
          </DataCardStatusIcon>
          <DataCardStatusTitle>Total</DataCardStatusTitle>
        </DataCardStatus>

        <DataCardStat>
          <DataCardStatNumber>{total}</DataCardStatNumber>
          <DataCardStatUnit>Devices</DataCardStatUnit>
        </DataCardStat>
      </DataCard>

      <DataCard error>
        <DataCardStatus border>
          <DataCardStatusIcon viewBox="0 0 24 24" error>
            <path d="M3,19.2h15.1v-3.6H3V19.2z M4.8,16.5h1.8v1.8H4.8V16.5z M3,13.8h15.1v-3.6H3V13.8z M4.8,11.1h1.8v1.8H4.8 V11.1z M3,4.8v3.6h18V4.8H3z M6.6,7.5H4.8V5.7h1.8V7.5z M19.6,19.2H21v-1.4h-1.4V19.2z M19.6,15.6H21v-5h-1.4V15.6z" />
          </DataCardStatusIcon>
          <DataCardStatusTitle>Error</DataCardStatusTitle>
        </DataCardStatus>

        <DataCardStat>
          <DataCardStatNumber>0</DataCardStatNumber>
          <DataCardStatUnit>Devices</DataCardStatUnit>
        </DataCardStat>
      </DataCard>

      <DataCard running>
        <DataCardStatus border>
          <DataCardStatusIcon viewBox="0 0 24 24" running>
            <path d="M3,19.2h8.5v-3.6H3V19.2z M4.8,16.5h1.8v1.8H4.8V16.5z M13.1,19.2h1.6V16h-1.6V19.2z M16.3,19.2h1.6v-5.7h-1.6 V19.2z M3,4.8v3.6H21V4.8H3z M6.6,7.5H4.8V5.7h1.8V7.5z M19.4,19.2H21v-8.4h-1.6V19.2z M17.8,10.2H3v3.6h11.7v-1.9l3.1,0V10.2z M6.6,12.9H4.8v-1.8h1.8V12.9z" />
          </DataCardStatusIcon>
          <DataCardStatusTitle>Runing</DataCardStatusTitle>
        </DataCardStatus>

        <DataCardStat>
          <DataCardStatNumber>{runing}</DataCardStatNumber>
          <DataCardStatUnit>Devices</DataCardStatUnit>
        </DataCardStat>
      </DataCard>

      <DataCard stoped>
        <DataCardStatus border>
          <DataCardStatusIcon viewBox="0 0 24 24" stoped>
            <path d="M6.8,10.2H3v3.6h7.4L6.8,10.2z M6.6,12.9H4.8v-1.8h1.8V12.9z M3,6.4v2h2L3,6.4z M17.2,13.8H21v-3.6h-7.4 L17.2,13.8z M8.2,4.8l3.6,3.6H21V4.8H8.2z M21,17.6v-2h-2L21,17.6z M3,15.6v3.6h12.8l-3.6-3.6H3z M6.6,18.3H4.8v-1.8h1.8V18.3z M3.6,4.8l14.5,14.5h2.3L5.9,4.8H3.6z" />
          </DataCardStatusIcon>
          <DataCardStatusTitle>Stoped</DataCardStatusTitle>
        </DataCardStatus>

        <DataCardStat>
          <DataCardStatNumber>{Stop}</DataCardStatNumber>
          <DataCardStatUnit>Devices</DataCardStatUnit>
        </DataCardStat>
      </DataCard>
    </Data>
  </State>
);

Overview.propTypes = {
  total: PropTypes.number.isRequired,
  runing: PropTypes.number.isRequired,
  Stop: PropTypes.number.isRequired,
};

export default Overview;
