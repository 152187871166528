/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { calcrem } from '../../utils';

const State = styled.div`
  width: 100%;
  position: relative;
`;

export { State };
