import styled from 'styled-components';
import { calcrem } from '../../utils';

const State = styled.footer`
  width: 100%;
  padding: ${calcrem(16)} 0;
  position: relative;
`;

const Copyright = styled.div`
  margin: 0 auto 0 0;
`;

const CopyrightText = styled.span`
  color: #666666;
  font-size: ${calcrem(10)};

  span {
    @media (max-width: 767.98px) {
      display: block;
    }

    @media (min-width: 768px) {
    }
  }
  a {
    color: #666666;
    text-decoration: none;
  }
`;

const Socials = styled.div`
  margin: 0 0 0 auto;
`;

const SocialsUnordered = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const SocialsList = styled.li`
  margin: 0 ${calcrem(4)};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const SocialsLink = styled.a`
  display: block;
`;

const SocialsIcon = styled.svg`
  width: ${calcrem(24)};
  height: ${calcrem(24)};
  fill: #666666;
`;

export {
  State,
  Copyright,
  CopyrightText,
  Socials,
  SocialsUnordered,
  SocialsList,
  SocialsLink,
  SocialsIcon,
};
