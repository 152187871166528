import {
  State,
  Title,
  Support,
  SupportLink,
  SupportLabel,
  SupportIcon,
  SupportText,
} from './styled';
import { Container } from '../../components';

const Titlebar = () => (
  <State>
    <Container alignItems="center">
      <Title>Thai Stanley Eletric Dashboard</Title>

      <Support>
        <SupportLabel>Support</SupportLabel>

        <SupportLink href="tel:025182722">
          <SupportIcon viewBox="0 0 24 24">
            <path d="M6.62,10.86c1.44,2.66,3.76,4.84,6.59,6.2l2.2-2.07c0.27-0.25,0.67-0.34,1.02-0.23c1.12,0.35,2.33,0.54,3.57,0.54c0.55,0,1,0.42,1,0.94v3.28c0,0.52-0.45,0.94-1,0.94c-9.39,0-17-7.16-17-16c0-0.52,0.45-0.94,1-0.94h3.5c0.55,0,1,0.42,1,0.94c0,1.18,0.2,2.31,0.57,3.36c0.11,0.33,0.03,0.7-0.25,0.96L6.62,10.86z" />
          </SupportIcon>

          <SupportText>Phone</SupportText>
        </SupportLink>

        <SupportLink href="mailto:ananta@filtervision-thai.com">
          <SupportIcon viewBox="0 0 24 24">
            <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" />
          </SupportIcon>

          <SupportText>Email</SupportText>
        </SupportLink>
      </Support>
    </Container>
  </State>
);

export default Titlebar;
