import {
  State,
  Copyright,
  CopyrightText,
  Socials,
  SocialsUnordered,
  SocialsList,
  SocialsLink,
  SocialsIcon,
} from './styled';
import { Container } from '../../components';

const Footer = () => (
  <State>
    <Container alignItems="center">
      <Copyright>
        <CopyrightText>
          <span>© 2020 Filter Vision. All rights reserved.</span>
          <span>Water Intpad Console API by Filter Vision,</span>
          <span>
            {' '}
            Application by{' '}
            <a
              href="https://www.thisisclick.co.th/"
              target="_blank"
              rel="noreferrer">
              {' '}
              This is Click Digital
            </a>
            .
          </span>
        </CopyrightText>
      </Copyright>

      <Socials>
        <SocialsUnordered>
          <SocialsList>
            <SocialsLink
              href="https://www.facebook.com/"
              target="_blank"
              rel="noreferrer"
              aria-label="Facebook">
              <SocialsIcon viewBox="0 0 24 24">
                <path d="M13.24,20v-7.3h2.45l0.37-2.84h-2.82V8.04c0-0.82,0.23-1.38,1.41-1.38l1.51,0V4.11C15.89,4.08,15,4,13.96,4c-2.17,0-3.66,1.33-3.66,3.76v2.1H7.85v2.84h2.46V20H13.24z" />
              </SocialsIcon>
            </SocialsLink>
          </SocialsList>

          <SocialsList>
            <SocialsLink
              href="https://twitter.com/"
              target="_blank"
              rel="noreferrer"
              aria-label="Twitter">
              <SocialsIcon viewBox="0 0 24 24">
                <path d="M9.18,18.19c5.74,0,8.89-4.76,8.89-8.89c0-0.14,0-0.27-0.01-0.4c0.61-0.44,1.14-0.99,1.56-1.62 c-0.56,0.25-1.16,0.42-1.79,0.49c0.64-0.39,1.14-1,1.37-1.73c-0.6,0.36-1.27,0.62-1.98,0.76c-0.57-0.61-1.38-0.99-2.28-0.99 c-1.72,0-3.12,1.4-3.12,3.12c0,0.25,0.03,0.48,0.08,0.71c-2.6-0.13-4.9-1.37-6.44-3.26c-0.27,0.46-0.42,1-0.42,1.57 c0,1.08,0.55,2.04,1.39,2.6C5.9,10.54,5.42,10.4,5,10.16c0,0.01,0,0.03,0,0.04c0,1.51,1.08,2.78,2.51,3.06 c-0.26,0.07-0.54,0.11-0.82,0.11c-0.2,0-0.4-0.02-0.59-0.06c0.4,1.24,1.55,2.14,2.92,2.17c-1.07,0.84-2.41,1.34-3.88,1.34 c-0.25,0-0.5-0.01-0.74-0.04C5.77,17.67,7.41,18.19,9.18,18.19" />
              </SocialsIcon>
            </SocialsLink>
          </SocialsList>

          <SocialsList>
            <SocialsLink
              href="https://line.me/"
              target="_blank"
              rel="noreferrer"
              aria-label="Line Messenger">
              <SocialsIcon viewBox="0 0 24 24">
                <path d="M12,6c-4.05,0-7.35,2.67-7.35,5.96c0,2.95,2.61,5.42,6.14,5.88c0.24,0.05,0.56,0.16,0.65,0.36 c0.07,0.19,0.05,0.48,0.02,0.66c0,0-0.09,0.52-0.1,0.63c-0.03,0.19-0.15,0.73,0.64,0.4c0.78-0.33,4.23-2.49,5.77-4.27h0 c1.07-1.17,1.58-2.35,1.58-3.67C19.35,8.67,16.05,6,12,6z M9.12,13.92H7.66c-0.21,0-0.39-0.17-0.39-0.39v-2.92 c0-0.21,0.17-0.39,0.39-0.39c0.21,0,0.39,0.17,0.39,0.39v2.53h1.07c0.21,0,0.39,0.17,0.39,0.39C9.51,13.74,9.34,13.92,9.12,13.92z M10.63,13.53c0,0.21-0.17,0.39-0.39,0.39c-0.21,0-0.39-0.17-0.39-0.39v-2.92c0-0.21,0.17-0.39,0.39-0.39 c0.21,0,0.39,0.17,0.39,0.39V13.53z M14.15,13.53c0,0.17-0.11,0.31-0.26,0.37c-0.04,0.01-0.08,0.02-0.12,0.02 c-0.12,0-0.24-0.06-0.31-0.15l-1.5-2.04v1.81c0,0.21-0.17,0.39-0.39,0.39c-0.21,0-0.39-0.17-0.39-0.39v-2.92 c0-0.17,0.11-0.31,0.26-0.37c0.04-0.01,0.08-0.02,0.12-0.02c0.12,0,0.24,0.06,0.31,0.15l1.5,2.04v-1.81c0-0.21,0.17-0.39,0.39-0.39 s0.39,0.17,0.39,0.39V13.53z M16.51,11.69L16.51,11.69c0.21,0,0.39,0.17,0.39,0.39c0,0.21-0.17,0.39-0.39,0.39h-1.07v0.69h1.07 c0.21,0,0.39,0.17,0.39,0.39c0,0.21-0.17,0.39-0.39,0.39h-1.46c-0.21,0-0.39-0.17-0.39-0.39v-1.46c0,0,0,0,0,0v0v-1.46c0,0,0,0,0,0 c0-0.21,0.17-0.39,0.39-0.39h1.46c0.21,0,0.39,0.17,0.39,0.39c0,0.21-0.17,0.39-0.39,0.39h-1.07v0.69H16.51z" />
              </SocialsIcon>
            </SocialsLink>
          </SocialsList>

          <SocialsList>
            <SocialsLink
              href="https://youtube.com/"
              target="_blank"
              rel="noreferrer"
              aria-label="Youtube">
              <SocialsIcon viewBox="0 0 24 24">
                <path d="M20.07,8.71c0,0-0.16-1.14-0.66-1.64c-0.63-0.66-1.33-0.66-1.65-0.7C15.46,6.21,12,6.21,12,6.21H12c0,0-3.46,0-5.76,0.17c-0.32,0.04-1.02,0.04-1.65,0.7c-0.49,0.5-0.65,1.64-0.65,1.64s-0.17,1.33-0.17,2.67v1.25c0,1.33,0.17,2.67,0.17,2.67s0.16,1.14,0.65,1.64c0.63,0.66,1.45,0.64,1.82,0.7c1.32,0.13,5.6,0.17,5.6,0.17s3.46-0.01,5.77-0.17c0.32-0.04,1.02-0.04,1.65-0.7c0.49-0.5,0.66-1.64,0.66-1.64s0.16-1.33,0.16-2.67v-1.25C20.24,10.04,20.07,8.71,20.07,8.71zM10.3,14.14V9.51l4.45,2.32L10.3,14.14z" />
              </SocialsIcon>
            </SocialsLink>
          </SocialsList>
        </SocialsUnordered>
      </Socials>
    </Container>
  </State>
);

export default Footer;
