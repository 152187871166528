/* eslint-disable jsx-a11y/alt-text */
import {
  State,
  Brand,
  BrandLogo,
  Title,
  Action,
  ActionProfile,
  ActionLogout,
  ActionProfileLogo,
  // ActionProfileImage,
  ActionProfileName,
  ActionLogoutText,
  ActionLogoutIcon,
} from './styled';
import { Container } from '../../components';

const Header = () => (
  <State>
    <Container alignItems="center">
      <Brand>
        <BrandLogo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32">
          <path d="M16.5,27.19c-6.19,0-11.2-5.02-11.2-11.23c0-5.66,4.18-10.31,9.6-11.1V0C6.8,0.8,0.5,7.64,0.5,15.96 C0.5,24.81,7.65,32,16.48,32c5.3,0,9.98-2.58,12.9-6.56l-4.16-2.45C23.17,25.55,20.04,27.19,16.5,27.19z M18.1,0v4.86 c5.42,0.79,9.6,5.44,9.6,11.1c0,1.44-0.29,2.81-0.77,4.07l4.16,2.45c0.9-1.99,1.41-4.2,1.41-6.53C32.5,7.65,26.18,0.8,18.1,0z" />
        </BrandLogo>
      </Brand>

      <Title>WATER INTPAD</Title>

      <Action>
        <ActionProfile>
          <ActionProfileLogo>
            <img src="/images/administrator.png" />
          </ActionProfileLogo>
          <ActionProfileName>Administrator</ActionProfileName>
        </ActionProfile>

        <ActionLogout type="button">
          <ActionLogoutIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 1000">
            <path d="M622.5,990H50.8C26.3,990,10,973.7,10,949.2V50.8C10,26.3,26.3,10,50.8,10h571.7c24.5,0,40.8,16.3,40.8,40.8v285.8c0,24.5-16.3,40.8-40.8,40.8s-40.8-16.3-40.8-40.8v-245h-490v816.7h490v-245c0-24.5,16.3-40.8,40.8-40.8s40.8,16.3,40.8,40.8v285.8C663.3,973.7,647,990,622.5,990z" />
            <path d="M949.2,540.8H336.7c-24.5,0-40.8-16.3-40.8-40.8c0-24.5,16.3-40.8,40.8-40.8h612.5c24.5,0,40.8,16.3,40.8,40.8C990,524.5,973.7,540.8,949.2,540.8z" />
            <path d="M949.2,540.8c-12.3,0-20.4-4.1-28.6-12.3L757.3,365.3c-16.3-16.3-16.3-40.8,0-57.2c16.3-16.3,40.8-16.3,57.2,0l163.3,163.3c16.3,16.3,16.3,40.8,0,57.2C969.6,536.8,961.4,540.8,949.2,540.8z" />
            <path d="M785.8,704.2c-12.3,0-20.4-4.1-28.6-12.3c-16.3-16.3-16.3-40.8,0-57.2l163.3-163.3c16.3-16.3,40.8-16.3,57.2,0c16.3,16.3,16.3,40.8,0,57.2L814.4,691.9C806.3,700.1,798.1,704.2,785.8,704.2z" />
          </ActionLogoutIcon>

          <ActionLogoutText>Logout</ActionLogoutText>
        </ActionLogout>
      </Action>
    </Container>
  </State>
);

export default Header;
