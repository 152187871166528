/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import FusionCharts from 'fusioncharts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import {
  State,
  Header,
  HeaderLabel,
  Bar,
  BarData,
  Charts,
  ChartColumn,
  ChartsValue,
  ChartsLabel,
} from './styled';

ReactFC.fcRoot(FusionCharts, Widgets, FusionTheme);

const List = ({ dataProvier, dataInput }) => {
  const dataSourceCurrent = {
    chart: {
      // caption: 'Current',
      // subcaption: 'AMP',
      captionFontSize: '16',
      captionFontColor: '#000000',
      subcaptionFontSize: '14',
      lowerLimit: '0',
      upperLimit: '100',
      theme: 'fusion',

      gaugeFillRatio: '500',
    },
    colorRange: {
      color: [
        {
          minValue: '0',
          maxValue: '50',
          code: '#fd5f53',
        },
        {
          minValue: '50',
          maxValue: '100',
          code: '#22cc36',
        },
      ],
    },
    dials: {
      dial: [
        {
          value: dataInput.current,
        },
      ],
    },
  };

  const dataSourceFlow = {
    chart: {
      // caption: 'Water Flow',
      // subcaption: 'LPM',
      captionFontSize: '16',
      captionFontColor: '#000000',
      subcaptionFontSize: '14',
      lowerLimit: '0',
      upperLimit: '100',
      theme: 'fusion',
    },
    colorRange: {
      color: [
        {
          minValue: '0',
          maxValue: '50',
          code: '#fd5f53',
        },
        {
          minValue: '50',
          maxValue: '100',
          code: '#22cc36',
        },
      ],
    },
    dials: {
      dial: [
        {
          value: dataInput.flow,
        },
      ],
    },
  };

  const dataSourcePressure = {
    chart: {
      // caption: 'Pressure',
      // subcaption: 'AMP',
      captionFontSize: '16',
      captionFontColor: '#000000',
      subcaptionFontSize: '14',
      lowerLimit: '0',
      upperLimit: '100',
      theme: 'fusion',
    },
    colorRange: {
      color: [
        {
          minValue: '0',
          maxValue: '50',
          code: '#fd5f53',
        },
        {
          minValue: '50',
          maxValue: '100',
          code: '#22cc36',
        },
      ],
    },
    dials: {
      dial: [
        {
          value: dataInput.pressure,
        },
      ],
    },
  };

  const dataSourceTemp1 = {
    chart: {
      // caption: 'Waterproof Temp 1',
      // subcaption: '°C',
      captionFontSize: '16',
      captionFontColor: '#000000',
      subcaptionFontSize: '14',
      lowerLimit: '0',
      upperLimit: '100',
      theme: 'fusion',
    },
    colorRange: {
      color: [
        {
          minValue: '0',
          maxValue: '50',
          code: '#fd5f53',
        },
        {
          minValue: '50',
          maxValue: '100',
          code: '#22cc36',
        },
      ],
    },
    dials: {
      dial: [
        {
          value: dataInput.temp1,
        },
      ],
    },
  };

  const dataSourceTemp2 = {
    chart: {
      // caption: 'Waterproof Temp 2',
      // subcaption: '°C',
      captionFontSize: '16',
      captionFontColor: '#000000',
      subcaptionFontSize: '14',
      lowerLimit: '0',
      upperLimit: '100',
      theme: 'fusion',
    },
    colorRange: {
      color: [
        {
          minValue: '0',
          maxValue: '50',
          code: '#fd5f53',
        },
        {
          minValue: '50',
          maxValue: '100',
          code: '#22cc36',
        },
      ],
    },
    dials: {
      dial: [
        {
          value: dataInput.temp2,
        },
      ],
    },
  };

  const chartConfigsCurrent = {
    type: 'angulargauge',
    width: '100%',
    height: '150',
    dataFormat: 'json',
    dataSource: dataSourceCurrent,
  };

  const chartConfigsFlow = {
    type: 'angulargauge',
    width: '100%',
    height: '150',
    dataFormat: 'json',
    dataSource: dataSourceFlow,
  };

  const chartConfigsPressure = {
    type: 'angulargauge',
    width: '100%',
    height: '150',
    dataFormat: 'json',
    dataSource: dataSourcePressure,
  };

  const chartConfigsTemp1 = {
    type: 'angulargauge',
    width: '100%',
    height: '150',
    dataFormat: 'json',
    dataSource: dataSourceTemp1,
  };

  const chartConfigsTemp2 = {
    type: 'angulargauge',
    width: '100%',
    height: '150',
    dataFormat: 'json',
    dataSource: dataSourceTemp2,
  };

  return (
    <State>
      <Header>
        <HeaderLabel first>Provier</HeaderLabel>
        <HeaderLabel>Current</HeaderLabel>
        <HeaderLabel>Water Flow</HeaderLabel>
        <HeaderLabel>Pressure</HeaderLabel>
        <HeaderLabel>Waterproof Temp 1</HeaderLabel>
        <HeaderLabel last>Waterproof Temp 2</HeaderLabel>
      </Header>

      <Bar>
        <BarData>{dataProvier}</BarData>
        <BarData>{dataInput.current} AMP</BarData>
        <BarData>{dataInput.flow} LPM</BarData>
        <BarData>{dataInput.pressure} PSI</BarData>
        <BarData>{dataInput.temp1} °C</BarData>
        <BarData>{dataInput.temp2} °C</BarData>
      </Bar>

      <Charts>
        <ChartColumn>
          <ReactFC {...chartConfigsCurrent} />
          <ChartsValue>{dataInput.current} AMP</ChartsValue>
          <ChartsLabel>Current</ChartsLabel>
        </ChartColumn>

        <ChartColumn>
          <ReactFC {...chartConfigsFlow} />
          <ChartsValue>{dataInput.flow} LPM</ChartsValue>
          <ChartsLabel>Water Flow</ChartsLabel>
        </ChartColumn>

        <ChartColumn>
          <ReactFC {...chartConfigsPressure} />
          <ChartsValue>{dataInput.pressure} PSI</ChartsValue>
          <ChartsLabel>Pressure</ChartsLabel>
        </ChartColumn>

        <ChartColumn>
          <ReactFC {...chartConfigsTemp1} />
          <ChartsValue>{dataInput.temp1} °C</ChartsValue>
          <ChartsLabel>Waterproof Temp 1</ChartsLabel>
        </ChartColumn>

        <ChartColumn>
          <ReactFC {...chartConfigsTemp2} />
          <ChartsValue>{dataInput.temp2} °C</ChartsValue>
          <ChartsLabel>Waterproof Temp 2</ChartsLabel>
        </ChartColumn>
      </Charts>
    </State>
  );
};

List.propTypes = {
  dataProvier: PropTypes.string.isRequired,
  dataCurrent: PropTypes.number.isRequired,
  dataFlow: PropTypes.number.isRequired,
  dataPre: PropTypes.number.isRequired,
  dataTemp1: PropTypes.number.isRequired,
  dataTemp2: PropTypes.number.isRequired,
};

export default List;
