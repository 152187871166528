import styled from 'styled-components';
import { calcrem } from '../../utils';

const State = styled.div`
  width: 100%;
  position: relative;

  @media (max-width: 767.98px) {
    padding: 0 ${calcrem(16)};
    margin: 0 0 ${calcrem(64)};
  }

  @media (min-width: 768px) {
    padding: 0 ${calcrem(32)};
    margin: 0 0 ${calcrem(64)};
  }
`;

const Title = styled.h2`
  margin: 0 0 ${calcrem(16)};
  font-weight: 700;

  @media (max-width: 767.98px) {
    font-size: ${calcrem(18)};
  }

  @media (min-width: 768px) {
    font-size: ${calcrem(24)};
  }
`;

const Data = styled.div`
  @media (max-width: 575.98px) {
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: 1200px) {
    display: flex;
  }
`;

const DataCard = styled.div`
  display: flex;
  background: #ffffff;
  border-top-right-radius: ${calcrem(4)};
  border-bottom-right-radius: ${calcrem(4)};
  box-shadow: 0 0 ${calcrem(8)} 0 rgba(0, 0, 0, 0.125);
  overflow: hidden;

  ${(props) => props.total && `border-left: ${calcrem(3)} solid #3d5afe`};
  ${(props) => props.error && `border-left: ${calcrem(3)} solid #fd5f53`};
  ${(props) => props.running && `border-left: ${calcrem(3)} solid #22cc36`};
  ${(props) => props.stoped && `border-left: ${calcrem(3)} solid #febe07`};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 575.98px) {
    width: 100%;
    padding: ${calcrem(16)} ${calcrem(8)};

    ${(props) =>
      props.total &&
      `
      margin: 0  0 ${calcrem(16)};
    `};

    ${(props) =>
      props.error &&
      `
      margin: 0  0 ${calcrem(16)};
    `};

    ${(props) =>
      props.running &&
      `
      margin: 0  0 ${calcrem(16)};
    `};
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: calc(50% - ${calcrem(8)});
    padding: ${calcrem(16)} ${calcrem(8)};

    ${(props) =>
      props.total &&
      `
      margin: 0 ${calcrem(16)} ${calcrem(16)} 0;
    `};

    ${(props) =>
      props.error &&
      `
      margin: 0 0 ${calcrem(16)};
    `};

    ${(props) =>
      props.running &&
      `
      margin: 0 ${calcrem(16)} 0 0;
    `};
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    width: calc(50% - ${calcrem(16)});
    padding: ${calcrem(32)} ${calcrem(16)};

    ${(props) =>
      props.total &&
      `
      margin: 0 ${calcrem(32)} ${calcrem(32)} 0;
    `};

    ${(props) =>
      props.error &&
      `
      margin: 0 0 ${calcrem(32)};
    `};

    ${(props) =>
      props.running &&
      `
      margin: 0 ${calcrem(32)} 0 0;
    `};
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: calc(50% - ${calcrem(16)});
    padding: ${calcrem(32)} ${calcrem(16)};

    ${(props) =>
      props.total &&
      `
      margin: 0 ${calcrem(32)} ${calcrem(32)} 0;
    `};

    ${(props) =>
      props.error &&
      `
      margin: 0 0 ${calcrem(32)};
    `};

    ${(props) =>
      props.running &&
      `
      margin: 0 ${calcrem(32)} 0 0;
    `};
  }

  @media (min-width: 1200px) {
    width: calc(25% - ${calcrem(24)});
    padding: ${calcrem(32)} ${calcrem(16)};
    margin: 0 ${calcrem(16)};
  }
`;

const DataCardStatus = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${(props) =>
    props.border &&
    `
    &::after{
      content: "";
      width: ${calcrem(1)};
      height: 100%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      background: #e1e4e8;
    }
  `};
`;

const DataCardStatusIcon = styled.svg`
  width: ${calcrem(48)};
  height: ${calcrem(48)};

  ${(props) => props.total && `fill: #3d5afe`};
  ${(props) => props.error && `fill: #fd5f53`};
  ${(props) => props.running && `fill: #22cc36`};
  ${(props) => props.stoped && `fill: #febe07`};
`;

const DataCardStatusTitle = styled.span`
  display: block;
  color: #000000;
  font-size: ${calcrem(14)};
  font-weight: 700;
`;

const DataCardStat = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const DataCardStatNumber = styled.span`
  display: block;
  margin: 0 ${calcrem(4)} 0 0;
  line-height: 1;
  color: #000000;
  font-size: ${calcrem(48)};
  font-weight: 700;
`;

const DataCardStatUnit = styled.span`
  display: block;
  margin: 0 0 0 ${calcrem(4)};
  line-height: 1.75;
  color: #6a737d;
  font-size: ${calcrem(14)};
`;

export {
  State,
  Title,
  Data,
  DataCard,
  DataCardStatus,
  DataCardStatusTitle,
  DataCardStatusIcon,
  DataCardStat,
  DataCardStatNumber,
  DataCardStatUnit,
};
