import styled from 'styled-components';
import { calcrem } from '../../utils';

const State = styled.div`
  width: calc(100% - ${calcrem(64)});
  position: relative;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.125);

  @media (max-width: 767.98px) {
    display: none;
  }

  @media (min-width: 768px) {
    margin: 0 ${calcrem(32)} ${calcrem(64)};
  }

  &:last-child {
    margin-bottom: ${calcrem(128)};
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background: linear-gradient(
    90deg,
    rgb(61, 90, 254) 0%,
    rgb(31, 45, 127) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.125) 0 0 0.5rem 0;
  border-radius: 0.25rem 0.25rem 0 0;
`;

const HeaderLabel = styled.div`
  width: 16.666%;
  padding: ${calcrem(16)};
  position: relative;
  color: rgb(255, 255, 255);
  font-size: ${calcrem(14)};
  font-weight: 700;
  white-space: nowrap;

  &::after {
    content: '';
    width: 0.0625rem;
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0px, -50%);
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: rgb(233, 233, 240);
`;

const BarData = styled.div`
  width: 16.666%;
  padding: ${calcrem(16)};
  position: relative;
  color: rgb(0, 0, 0);
  font-size: ${calcrem(14)};
  white-space: nowrap;

  &::after {
    content: '';
    width: 0.0625rem;
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0px, -50%);
    background-color: rgba(255, 255, 255, 1);
  }
`;

const Charts = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  // background-color: #f7f7fa;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
`;

const ChartColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 9;

  @media (max-width: 575.98px) {
    width: 100%;
    margin: ${calcrem(16)} 0;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 50%;
    margin: ${calcrem(16)} 0;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    width: 50%;
    margin: ${calcrem(16)} 0;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: 33.333%;
    margin: ${calcrem(16)} 0;
  }

  @media (min-width: 1200px) {
    width: 20%;
  }

  svg > g[class^='raphael-group-'] > text {
    display: none;
  }
`;

const ChartsValue = styled.span`
  display: block;
  width: 100%;
  margin: 0 0 ${calcrem(8)};
  font-size: ${calcrem(15)};
  text-align: center;
`;

const ChartsLabel = styled.span`
  display: block;
  width: 100%;
  margin: 0 0 ${calcrem(32)};
  font-size: ${calcrem(15)};
  font-weight: 500;
  text-align: center;
`;

export {
  State,
  Header,
  HeaderLabel,
  Bar,
  BarData,
  Charts,
  ChartColumn,
  ChartsValue,
  ChartsLabel,
};
