import { State, Icon, IconLogo } from './styled';

const Loading = () => {
  return (
    <State>
      <Icon>
        <IconLogo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32">
          <path d="M16.5,27.19c-6.19,0-11.2-5.02-11.2-11.23c0-5.66,4.18-10.31,9.6-11.1V0C6.8,0.8,0.5,7.64,0.5,15.96 C0.5,24.81,7.65,32,16.48,32c5.3,0,9.98-2.58,12.9-6.56l-4.16-2.45C23.17,25.55,20.04,27.19,16.5,27.19z M18.1,0v4.86 c5.42,0.79,9.6,5.44,9.6,11.1c0,1.44-0.29,2.81-0.77,4.07l4.16,2.45c0.9-1.99,1.41-4.2,1.41-6.53C32.5,7.65,26.18,0.8,18.1,0z" />
        </IconLogo>
      </Icon>

      <div>WATER INTPAD CONSOLE</div>

      {/* <svg viewBox="0 0 24 24">
        <path d="M12,18.28c-3.48,0-6.3-2.82-6.3-6.3c0-3.18,2.35-5.79,5.4-6.23V3.02C6.55,3.47,3,7.31,3,11.98c0,4.97,4.02,9,8.99,9 c2.98,0,5.62-1.45,7.25-3.68l-2.34-1.38C15.75,17.36,13.99,18.28,12,18.28z M12.9,3.02v2.73c3.05,0.44,5.4,3.05,5.4,6.23 c0,0.81-0.16,1.58-0.43,2.29l2.34,1.38c0.5-1.12,0.79-2.36,0.79-3.66C21,7.32,17.45,3.47,12.9,3.02z" />
      </svg> */}
    </State>
  );
};

export default Loading;
