import styled from 'styled-components';
import { calcrem } from '../../utils';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  @media (max-width: 767.98px) {
    padding: 0 ${calcrem(16)};
  }

  @media (min-width: 768px) {
    padding: 0 ${calcrem(32)};
  }
`;

export default Container;
